import { useMutation } from "@tanstack/react-query";
import { useDispatch } from "react-redux";
import { avisMethods } from "../../models/avis";
import apiCalls from "./apiCalls";

/**
 * Service pour gérer les avis.
 */
const avisService = {
	/**
	 * Utilise une mutation pour récupérer les avis.
	 *
	 * @returns {object} - L'objet de mutation de react-query.
	 */
	useFetchAvis() {
		const dispatch = useDispatch();

		return useMutation({
			mutationFn: apiCalls.fetchAvis,
			/**
			 * Callback exécuté en cas de succès de la mutation.
			 *
			 * @param {object} data - Les données retournées par la requête.
			 */
			onSuccess: data => {
				dispatch(avisMethods.fetchReviewsSuccess(data));
			}
		});
	}
};

export default avisService;
