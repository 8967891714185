import { useMutation } from "@tanstack/react-query";
import { useDispatch } from "react-redux";
import { statsMethods } from "../../models/metrics";
import apiCalls from "./apiCalls";

/**
 * Service pour gérer les statistiques.
 */
const statsService = {
	/**
	 * Utilise une mutation pour récupérer les statistiques globales.
	 * @returns {object} - La mutation de `react-query` pour récupérer les statistiques globales.
	 */
	useFetchGlobal() {
		const dispatch = useDispatch();

		return useMutation({
			mutationFn: apiCalls.fetchGlobals,
			onSuccess: async data => {
				dispatch(statsMethods.setGlobals(data));
				dispatch(statsMethods.setLoaded());
			},
			onError: error => {
				dispatch(statsMethods.setLoaded());
			}
		});
	},

	/**
	 * Utilise une mutation pour récupérer les rangs.
	 * @returns {object} - La mutation de `react-query` pour récupérer les rangs.
	 */
	useRanks() {
		const dispatch = useDispatch();

		return useMutation({
			mutationFn: apiCalls.fetchRanks,
			onSuccess: async data => {
				dispatch(statsMethods.setRanks(data));
			}
		});
	},

	/**
	 * Utilise une mutation pour récupérer les publications.
	 * @returns {object} - La mutation de `react-query` pour récupérer les publications.
	 */
	usePublications() {
		const dispatch = useDispatch();

		return useMutation({
			mutationFn: apiCalls.fetchPublications,
			onSuccess: async data => {
				dispatch(statsMethods.setPublicationsByTraining(data));
			}
		});
	},

	/**
	 * Utilise une mutation pour récupérer les forces.
	 * @returns {object} - La mutation de `react-query` pour récupérer les forces.
	 */
	useStrengths() {
		const dispatch = useDispatch();

		return useMutation({
			mutationFn: apiCalls.fetchStrengths,
			onSuccess: async data => {
				dispatch(statsMethods.setStrengths(data));
			}
		});
	},

	/**
	 * Utilise une mutation pour récupérer les similaires.
	 * @returns {object} - La mutation de `react-query` pour récupérer les similaires.
	 */
	useSimilars() {
		const dispatch = useDispatch();

		return useMutation({
			mutationFn: apiCalls.fetchSimilars,
			onSuccess: async data => {
				dispatch(statsMethods.setSimilars(data));
			}
		});
	}
};

export default statsService;
