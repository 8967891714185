import { useMutation } from "@tanstack/react-query";
import { useDispatch } from "react-redux";
import { faqMethods } from "../../models/Q&A";
import apiCalls from "./apiCalls";

/**
 * Service pour gérer les opérations de Q&A.
 */
const qnaService = {
	/**
	 * Utilise une mutation pour obtenir les questions en attente.
	 * @returns {object} - La mutation de react-query.
	 */
	usePending() {
		const dispatch = useDispatch();
		return useMutation({
			mutationFn: apiCalls.getPending,
			onMutate: ({ filter }) => {
				dispatch(faqMethods.setLoadingForFilter({ filter }));
			},
			onSuccess: (data, { filter }) => {
				dispatch(faqMethods.setPendingByFilter({ ...data, filter: filter }));
			}
		});
	},

	/**
	 * Utilise une mutation pour obtenir les questions affichées.
	 * @returns {object} - La mutation de react-query.
	 */
	useDisplayed() {
		const dispatch = useDispatch();
		return useMutation({
			mutationFn: apiCalls.getDisplayed,
			onSuccess: data => {
				dispatch(faqMethods.setDisplayed(data));
			},
			onError: error => {
				console.error("Échec de la récupération des questions affichées", error);
			}
		});
	},

	/**
	 * Utilise une mutation pour créer une nouvelle question.
	 * @returns {object} - La mutation de react-query.
	 */
	useCreate() {
		const dispatch = useDispatch();
		return useMutation({
			mutationFn: apiCalls.post,
			onSuccess: async data => {
				dispatch(faqMethods.addQuestion(data));
			}
		});
	},

	/**
	 * Utilise une mutation pour mettre à jour une question existante.
	 * @returns {object} - La mutation de react-query.
	 */
	usePatch() {
		const dispatch = useDispatch();
		return useMutation({
			mutationFn: apiCalls.patch
		});
	},

	/**
	 * Utilise une mutation pour supprimer une question.
	 * @returns {object} - La mutation de react-query.
	 */
	useDelete() {
		const dispatch = useDispatch();
		return useMutation({
			mutationFn: apiCalls.remove,
			onSuccess: async data => {
				dispatch(faqMethods.deleteQuestion({ id: data._id }));
			}
		});
	}
};

export default qnaService;
