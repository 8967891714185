import React from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useNavigate } from "react-router-dom";
import * as yup from "yup";
import InputsSchool from "./SUB/InputsSchool";
import clientService from "../../../services/client/clientService";
import "../form.css";
import { toast } from "react-toastify";
import { toastOptions } from "../../../Functions";

const Register_Form = () => {
	const navigate = useNavigate();

	const schema = yup.object().shape({
		mail: yup.string().email("Format d'email invalide").required("L'adresse email est requise."),
		phone: yup.string().required("Le numéro de téléphone est requis."),
		schoolId: yup.string().required("L'identifiant de l'école est requis."),
		file: yup.mixed().required("Le fichier certifiant l'identité de l'école est requis.")
	});

	const {
		control,
		handleSubmit,
		formState: { errors },
		setValue
	} = useForm({
		resolver: yupResolver(schema)
	});

	const service = clientService.useRegister();

	const onSubmit = async data => {
		try {
			const result = await service.mutateAsync(data);
			toast.success("Demande d'inscription envoyée, vérifiez votre boîte mail !", toastOptions);
		} catch (error) {
			console.log(error);
		}
	};

	const handleLogin = e => {
		e.preventDefault();
		navigate("/login");
	};

	return (
		<div className="login">
			<form onSubmit={handleSubmit(onSubmit)}>
				<p className="reponsive-back" onClick={() => navigate("/")}>
					{"<- "}
					Retour
				</p>

				<InputsSchool setValue={setValue} control={control} errors={errors} />

				{service?.error && <p className="error">{service.error?.message}</p>}

				<button className="l-but" type="submit" disabled={service.isPending}>
					{service.isPending ? "Chargement..." : "Envoyer →"}
				</button>
				<div className="redirect">
					<div>
						<p>Vous avez déjà un compte ?</p>
						<button type="button" onClick={handleLogin}>
							Se connecter.
						</button>
					</div>
				</div>
			</form>
		</div>
	);
};

export default Register_Form;
