import { Controller } from "react-hook-form";
import { StructureSelector } from "../../../Admin/StructureSelector/StructureSelector";
import { InputForm } from "../../Input/Input";

const InputsSchool = ({ control, setValue, errors }) => {
	const onSelect = data => {
		setValue("schoolId", data.structureId);
	};

	const handleFileChange = e => {
		const file = e.target.files[0];
		setValue("file", file);
	};

	return (
		<>
			<div className="labs">
				<label>Selectionnez votre école</label>
				<div style={{ position: "relative", paddingBottom: 20 }}>
					<StructureSelector onSelect={onSelect} />
					<p className={"error"}>{errors?.schoolId?.message}</p>
				</div>
			</div>

			<div className="labs">
				<label>Déposez un document de certification (PDF):</label>

				<div style={{ position: "relative", paddingBottom: 20 }}>
					<input id="file" type="file" accept=".pdf" onChange={handleFileChange} />

					<p className={"error"}>{errors?.file?.message}</p>
				</div>
			</div>

			<div className="labs">
				<label>Votre adresse mail :</label>
				<Controller
					name="mail"
					control={control}
					render={({ field }) => <InputForm field={field} error={errors.mail} type={"email"} />}
				/>
			</div>

			<div className="labs">
				<label>Votre numero de téléphone :</label>
				<Controller
					name="phone"
					control={control}
					render={({ field }) => <InputForm field={field} error={errors.phone} type={"text"} />}
				/>
			</div>
		</>
	);
};

export default InputsSchool;
