import { useMutation, useQuery } from "@tanstack/react-query";
import { useDispatch } from "react-redux";
import { adminMethods } from "../../models/admin";
import apiCalls from "./apiCalls";

/**
 * Service pour les opérations administratives.
 */
const adminService = {
	/**
	 * Utilise une mutation pour récupérer les données administratives.
	 * @returns {object} Mutation object
	 */
	useFetch() {
		const dispatch = useDispatch();

		return useMutation({
			mutationFn: apiCalls.fetch,
			onSuccess: async data => {
				dispatch(adminMethods.setAdminData(data));
			}
		});
	},

	/**
	 * Utilise une mutation pour créer un nouvel élément administratif.
	 * @returns {object} Mutation object
	 */
	useCreate() {
		const dispatch = useDispatch();

		return useMutation({
			mutationFn: apiCalls.create,
			onSuccess: async data => {
				dispatch(adminMethods.addClient(data));
			}
		});
	},

	/**
	 * Utilise une mutation pour récupérer les structures.
	 * @returns {object} Mutation object
	 */
	useStructures() {
		return useMutation({
			mutationFn: apiCalls.structures,
			onSuccess: async data => {
				return data;
			}
		});
	},

	/**
	 * Utilise une mutation pour récupérer les avis.
	 * @returns {object} Mutation object
	 */
	useAvis() {
		const dispatch = useDispatch();
		return useMutation({
			mutationFn: apiCalls.avis,
			onSuccess: async data => {
				dispatch(adminMethods.setAvis(data));
			}
		});
	},

	/**
	 * Utilise une mutation pour récupérer les leads.
	 * @returns {object} Mutation object
	 */
	useLeads() {
		const dispatch = useDispatch();
		return useMutation({
			mutationFn: apiCalls.leads,
			onSuccess: async data => {
				dispatch(adminMethods.setLeads(data));
			}
		});
	},

	/**
	 * Utilise une mutation pour la modération des avis.
	 * @returns {object} Mutation object
	 */
	useModeration() {
		const dispatch = useDispatch();
		return useMutation({
			mutationFn: apiCalls.moderation,
			onSuccess: async data => {
				dispatch(adminMethods.updateAvisStatus(data));
			}
		});
	},

	/**
	 * Utilise une mutation pour récupérer une structure spécifique.
	 * @returns {object} Mutation object
	 */
	useStructure() {
		return useMutation({
			mutationFn: apiCalls.structure,
			onSuccess: async data => {
				if (window.location.pathname === "/") {
					window.location.reload();
				} else {
					window.location.replace("/");
				}
			}
		});
	},

	/**
	 * Utilise une requête pour récupérer les détails d'une structure sélectionnée.
	 * @param {string} selectedStructure - L'identifiant de la structure sélectionnée
	 * @returns {object} Query object
	 */
	useFetchStructureDetails(selectedStructure) {
		return useQuery({
			queryKey: ["structureDetails", selectedStructure],
			queryFn: () => apiCalls.structureDetails({ structureId: selectedStructure }),
			enabled: !!selectedStructure,
			staleTime: Infinity
		});
	},

	/**
	 * Utilise une mutation pour mettre à niveau une formation.
	 * @returns {object} Mutation object
	 */
	useUpgradeTraining() {
		return useMutation({
			mutationFn: apiCalls.upgradeTraining
		});
	},

	/**
	 * Utilise une requête pour récupérer le token.
	 * @returns {object} Query object
	 */
	useToken() {
		return useQuery({
			queryKey: ["capitaineStudyToken"],
			queryFn: apiCalls.fetchToken,
			enabled: (() => {
				const tokenExpiration = localStorage.getItem("capitaineStudyTokenExpiration");
				return !tokenExpiration || new Date() > new Date(tokenExpiration);
			})(),
			cacheTime: Infinity,
			staleTime: Infinity
		});
	},

	/**
	 * Utilise une mutation pour récupérer les structures par leads.
	 * @returns {object} Mutation object
	 */
	useStructuresByLeads() {
		const dispatch = useDispatch();
		return useMutation({
			mutationFn: apiCalls.structuresByLeads,
			onSuccess: async data => {
				dispatch(adminMethods.setStructuresByLeads(data));
			}
		});
	}
};

export default adminService;
