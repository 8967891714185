import { apiClient } from "../clients";
import { adminRoute } from "../../utils/APIRoutes";
import axios from "axios";

/**
 * Récupère les données administratives.
 * @param {Object} credentials - Les informations d'identification.
 * @returns {Promise<Object>} Les données de réponse.
 */
const fetch = async credentials => {
	try {
		const response = await apiClient.get(adminRoute, credentials);
		return response.data;
	} catch (e) {
		console.log(e);
	}
};

/**
 * Crée une nouvelle entrée administrative.
 * @param {Object} credentials - Les informations d'identification.
 * @returns {Promise<Object>} Les données de réponse.
 * @throws {Error} Si une erreur se produit.
 */
const create = async credentials => {
	try {
		const response = await apiClient.post(adminRoute, credentials);
		return response.data;
	} catch (e) {
		if (e.response && e.response.data && e.response.data.message) {
			throw new Error(e.response.data.message);
		}
		throw new Error("Une erreur c'est produite.");
	}
};

/**
 * Récupère les structures en fonction de la recherche.
 * @param {Object} params - Les paramètres de recherche.
 * @param {string} params.search - Le terme de recherche.
 * @returns {Promise<Object>} Les données de réponse.
 */
const structures = async ({ search }) => {
	try {
		const params = new URLSearchParams({ search }).toString();
		const response = await apiClient.get(`${adminRoute}/structures?${params}`);
		return response.data;
	} catch (e) {
		console.log(e);
	}
};

/**
 * Récupère les avis en fonction des paramètres.
 * @param {Object} params - Les paramètres de recherche.
 * @param {number} params.skip - Le nombre d'éléments à ignorer.
 * @param {number} params.max - Le nombre maximum d'éléments à récupérer.
 * @param {string} params.status - Le statut des avis.
 * @param {boolean} params.forAll - Indique si la recherche est pour tous.
 * @returns {Promise<Object>} Les données de réponse.
 * @throws {Error} Si une erreur se produit.
 */
const avis = async ({ skip, max, status, forAll }) => {
	try {
		const params = new URLSearchParams({ skip, max, status, forAll }).toString();
		const response = await apiClient.get(`${adminRoute}/avis?${params}`);
		return response.data;
	} catch (e) {
		throw e;
	}
};

/**
 * Récupère les leads en fonction des paramètres.
 * @param {Object} params - Les paramètres de recherche.
 * @param {number} params.skip - Le nombre d'éléments à ignorer.
 * @param {number} params.max - Le nombre maximum d'éléments à récupérer.
 * @param {string} params.type - Le type de lead.
 * @param {boolean} params.forAll - Indique si la recherche est pour tous.
 * @param {string} params.structure - La structure des leads.
 * @returns {Promise<Object>} Les données de réponse.
 * @throws {Error} Si une erreur se produit.
 */
const leads = async ({ skip, max, type, forAll, structure }) => {
	try {
		const params = new URLSearchParams({ skip, max, type, forAll, structure }).toString();
		const response = await apiClient.get(`${adminRoute}/leads?${params}`);
		return response.data;
	} catch (e) {
		throw e;
	}
};

/**
 * Modifie les données de modération.
 * @param {Object} credentials - Les informations d'identification.
 * @returns {Promise<Object>} Les données de réponse.
 * @throws {Error} Si une erreur se produit.
 */
const moderation = async credentials => {
	try {
		const response = await apiClient.post(`${adminRoute}/moderation/patch`, credentials);
		return response.data;
	} catch (e) {
		throw e;
	}
};

/**
 * Crée une nouvelle structure.
 * @param {Object} credentials - Les informations d'identification.
 * @returns {Promise<Object>} Les données de réponse.
 * @throws {Error} Si une erreur se produit.
 */
const structure = async credentials => {
	try {
		const response = await apiClient.post(`${adminRoute}/structure`, credentials);
		return response.data;
	} catch (e) {
		throw e;
	}
};

/**
 * Récupère les détails d'une structure.
 * @param {Object} credentials - Les informations d'identification.
 * @param {string} credentials.structureId - L'identifiant de la structure.
 * @returns {Promise<Object>} Les données de réponse.
 * @throws {Error} Si une erreur se produit.
 */
const structureDetails = async credentials => {
	try {
		const response = await apiClient.get(`${adminRoute}/structure/${credentials.structureId}`);
		return response.data;
	} catch (e) {
		throw e;
	}
};

/**
 * Met à jour une formation.
 * @param {Object} credentials - Les informations d'identification.
 * @returns {Promise<Object>} Les données de réponse.
 * @throws {Error} Si une erreur se produit.
 */
const upgradeTraining = async credentials => {
	try {
		const response = await apiClient.post(`${adminRoute}/upgrade`, credentials);
		return response.data;
	} catch (e) {
		if (e.response && e.response.data && e.response.data.message) {
			throw new Error(e.response.data.message);
		}
		throw new Error("Une erreur c'est produite.");
	}
};

/**
 * Récupère un token d'authentification.
 * @returns {Promise<Object>} Les données de réponse.
 * @throws {Error} Si une erreur se produit.
 */
const fetchToken = async () => {
	try {
		const response = await axios.post(`https://www.capitainestudy.fr/wp-json/jwt-auth/v1/token`, {
			username: "omer.boutillier",
			password: "e6wxKHWVLtkaD2Dyb4ZmwYoI"
		});
		return response.data;
	} catch (e) {
		throw e;
	}
};

/**
 * Récupère les structures par leads.
 * @param {Object} credentials - Les informations d'identification.
 * @param {number} credentials.skip - Le nombre d'éléments à ignorer.
 * @returns {Promise<Object>} Les données de réponse.
 * @throws {Error} Si une erreur se produit.
 */
const structuresByLeads = async credentials => {
	try {
		const params = new URLSearchParams({ skip: credentials.skip }).toString();
		const response = await apiClient.get(`${adminRoute}/leads/structureByLeads?${params}`);
		return response.data;
	} catch (e) {
		throw e;
	}
};

const apiCalls = {
	fetch,
	create,
	structures,
	avis,
	leads,
	moderation,
	structure,
	structureDetails,
	upgradeTraining,
	fetchToken,
	structuresByLeads
};

export default apiCalls;
