import { Table } from "../Table/Table";
import { useDispatch, useSelector } from "react-redux";
import { adminMethods } from "../../models/admin";
import adminService from "../../services/admin/adminService";
import { useEffect, useState } from "react";
import { getColumnKeys, getColumnValues } from "../Leads/TableDecorator";
import { NewClientForm } from "./Create/NewClientForm";
import { Structure } from "./Structure/Structure";

export const TableAdminDecorator = () => {
	const dispatch = useDispatch();
	const selectedType = useSelector(state => state.admin[state.admin.selectedFilter]);
	const data = selectedType[selectedType.selected];

	const inSelection = useSelector(state => state.admin.selectedFilter);
	const fetchMore = async () => {
		try {
			dispatch(
				adminMethods.setSubFilterLoading({
					selectedFilter: inSelection,
					selected: selectedType.selected,
					loading: true
				})
			);
			await adminService.useFetch().mutateAsync({
				skip: data?.content.length,
				limit: 10,
				type: selectedType.selected
			});
		} catch (e) {
			console.error(e);
		}
	};

	const onSelect = filter => {
		dispatch(adminMethods.setSelectedSubFilter(filter));
	};

	const service = adminService.useFetch();

	useEffect(() => {
		const fetchData = async () => {
			await service.mutateAsync({
				skip: data?.content.length,
				max: data?.max,
				type: selectedType.selected
			});
		};

		if (data.loading) {
			fetchData();
		}
	}, [selectedType]);

	const columnKeys = getColumnKeys(data?.columns);
	const columnValues = getColumnValues(data?.columns);

	const formattedFilters = () => {
		return selectedType?.filters.map(filter => {
			return { key: filter.key, value: filter.value + " (" + selectedType[filter.key].total + ")" };
		});
	};

	const [displayForm, setDisplayForm] = useState(false);

	const preprocessRows = rows => {
		return rows.map(row => {
			const newRow = { ...row };
			columnKeys.forEach(key => {
				if (key.includes("request.phone")) {
					newRow["request.phone"] = newRow.request ? newRow.request.phone : "";
				}
				if (key.includes("request.fileId")) {
					newRow["request.fileId"] = newRow.request ? newRow.request.fileId : "";
				}
				if (key.includes("createdAt")) {
					newRow["createdAt"] = new Date(newRow.createdAt).toLocaleDateString();
				}
			});
			return newRow;
		});
	};

	const selectedStructure = useSelector(state => state.admin.clients.selectedStructure);

	const handleRowClick = row => {
		let structure = row?.structureId;
		if (!structure) return;

		dispatch(adminMethods.setSelectedStructure(structure));
	};

	return (
		<>
			{!selectedStructure ? (
				<>
					<button onClick={() => setDisplayForm(!displayForm)}>
						{!displayForm ? "+ Créer un compte client" : "Annuler"}
					</button>
					{displayForm && <NewClientForm />}
					<div style={{ marginTop: 20 }}>
						<Table
							columns={columnValues}
							rows={preprocessRows(data?.content)}
							total={data.total}
							loading={data?.loading}
							filters={formattedFilters()}
							fetchMore={fetchMore}
							onSelect={onSelect}
							rowsFormat={columnKeys}
							selected={selectedType.selected}
							onRowClick={handleRowClick}
						/>
					</div>
				</>
			) : (
				<>
					<button onClick={() => dispatch(adminMethods.setSelectedStructure(undefined))}>
						{"<-"} Retour
					</button>
					<Structure selectedStructure={selectedStructure} />
				</>
			)}
		</>
	);
};
