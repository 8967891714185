import { useMutation } from "@tanstack/react-query";
import { useDispatch } from "react-redux";
import { clientMethods } from "../../models/client";
import { useNavigate } from "react-router-dom";
import apiCalls from "./apiCall";

/**
 * Service client pour gérer les opérations liées aux clients.
 */
const clientService = {
	/**
	 * Utilise la mutation pour la connexion.
	 * @returns {Mutation} La mutation de connexion.
	 */
	useLogin() {
		const dispatch = useDispatch();

		return useMutation({
			mutationFn: apiCalls.login,
			onSuccess: async data => {
				dispatch(clientMethods.setIsLoaded(true));
				localStorage.setItem("token", data.token);
				window.location.replace("/");
			},
			onError: async error => {
				dispatch(clientMethods.setIsLoaded(true));
			}
		});
	},

	/**
	 * Utilise la mutation pour récupérer les données du client.
	 * @returns {Mutation} La mutation de récupération.
	 */
	useFetch() {
		const dispatch = useDispatch();
		const navigate = useNavigate();
		return useMutation({
			mutationFn: apiCalls.fetch,
			onSuccess: async data => {
				dispatch(clientMethods.setClient(data));
				navigate("/");
			},
			onError: async error => {
				const token = localStorage.getItem("token");
				localStorage.removeItem("token");
				dispatch(clientMethods.setIsLoaded(true));
				if (token) {
					navigate("/login");
				} else {
					navigate("/");
				}
			}
		});
	},

	/**
	 * Utilise la mutation pour mettre à jour les données du client.
	 * @returns {Mutation} La mutation de mise à jour.
	 */
	usePatch() {
		const dispatch = useDispatch();

		return useMutation({
			mutationFn: apiCalls.patch,
			onSuccess: async data => {
				dispatch(clientMethods.setField(data));
			}
		});
	},

	/**
	 * Utilise la mutation pour enregistrer un nouveau client.
	 * @returns {Mutation} La mutation d'enregistrement.
	 */
	useRegister() {
		return useMutation({
			mutationFn: apiCalls.register
		});
	},

	/**
	 * Utilise la mutation pour changer le mot de passe du client.
	 * @returns {Mutation} La mutation de changement de mot de passe.
	 */
	useChangePassword() {
		return useMutation({
			mutationFn: apiCalls.changePassword
		});
	},

	/**
	 * Utilise la mutation pour contacter le support client.
	 * @returns {Mutation} La mutation de contact.
	 */
	useContact() {
		return useMutation({
			mutationFn: apiCalls.contact
		});
	}
};

export default clientService;
