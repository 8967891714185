import appico from "../../../assets/hopteo_manager.webp";
import { Link } from "react-router-dom";
import "./style.css";
import { useEffect, useState } from "react";

export const NavigationBar = () => {
	const [isScrolled, setIsScrolled] = useState(false);

	useEffect(() => {
		const handleScroll = () => {
			const position = window.scrollY;
			if (position >= 500) {
				// Vous pouvez ajuster ce seuil selon vos besoins
				setIsScrolled(true);
			} else {
				setIsScrolled(false);
			}
		};

		window.addEventListener("scroll", handleScroll);

		return () => {
			window.removeEventListener("scroll", handleScroll);
		};
	}, []);

	return (
		<header className={`${isScrolled ? "display" : ""}`}>
			<img
				src={appico}
				alt="Logo hopteo manager"
				id="headerico"
				style={{ width: 200, height: "auto", objectFit: "contain" }}
			/>

			<nav className={"row2"} style={{ gap: 20 }}>
				<Link className={"landing-btn register"} to={"/register"}>
					Demander un rendez-vous
				</Link>
				<Link className={"landing-btn"} id="login" to={"/login"}>
					Se connecter
				</Link>
			</nav>
		</header>
	);
};
