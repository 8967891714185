import { useMutation, useQuery } from "@tanstack/react-query";
import { useDispatch } from "react-redux";
import { trainingsMethods } from "../../models/trainings";
import apiCalls from "./apiCalls";

/**
 * Service pour gérer les opérations liées aux formations.
 */
const trainingsService = {
	/**
	 * Utilise une requête pour récupérer les formations.
	 * @param {boolean} logged - Indique si l'utilisateur est connecté.
	 * @returns {object} - Objet de requête de `react-query`.
	 */
	useFetch(logged) {
		return useQuery({
			queryKey: ["trainings"],
			queryFn: () => apiCalls.fetch(),
			enabled: !!logged,
			staleTime: Infinity
		});
	},

	/**
	 * Utilise une mutation pour mettre à jour une formation.
	 * @returns {object} - Objet de mutation de `react-query`.
	 */
	usePatch() {
		const dispatch = useDispatch();
		return useMutation({
			mutationFn: apiCalls.patch,
			onSuccess: async data => {
				dispatch(trainingsMethods.updateTrainingField(data));
			}
		});
	},

	/**
	 * Utilise une mutation pour récupérer les publications.
	 * @returns {object} - Objet de mutation de `react-query`.
	 */
	usePublications() {
		const dispatch = useDispatch();
		return useMutation({
			mutationFn: apiCalls.fetchPublications,
			onSuccess: async data => {
				dispatch(trainingsMethods.setPublications(data));
			}
		});
	},

	/**
	 * Utilise une mutation pour améliorer une formation.
	 * @returns {object} - Objet de mutation de `react-query`.
	 */
	useUpgradeTraining() {
		const dispatch = useDispatch();

		return useMutation({
			mutationFn: apiCalls.upgrade,
			onSuccess: async data => {
				dispatch(trainingsMethods.updateTrainingField(data));
			}
		});
	}
};

export default trainingsService;
