import { processPieChartData } from "../Visites/utils";
import moment from "moment";

export const processStrengthData = (aggregatedData, selectedTraining = null) => {
	// Check if a specific training is selected and that training exists in the data
	if (selectedTraining && !aggregatedData[selectedTraining]) {
		return { labels: [], data: [], tableRows: [], totalLikes: 0 };
	}

	// Access the data for the selected training or default to the first training if none is selected
	const trainingData = selectedTraining
		? aggregatedData[selectedTraining]
		: Object.values(aggregatedData)[0];

	let categoryData = {};
	let totalLikes = 0;

	if (!trainingData) return { labels: [], data: [], tableRows: [], totalLikes: 0 };
	// Traverse through categories and keywords to accumulate data
	Object.keys(trainingData)?.forEach(category => {
		if (!trainingData[category]) return;
		Object.keys(trainingData[category])?.forEach(keyword => {
			const key = `${category} - ${keyword}`;
			const counts = trainingData[category][keyword].counts;
			totalLikes += counts;

			if (!categoryData[key]) {
				categoryData[key] = { counts: 0, category, keyword };
			}
			categoryData[key].counts += counts;
		});
	});

	// Prepare labels and data for the graph
	const labels = [];
	const data = [];
	const tableRows = [];

	// Sort data by counts descending for visualization
	const sortedData = Object.entries(categoryData).sort((a, b) => b[1].counts - a[1].counts);

	sortedData.forEach(([_, detail]) => {
		labels.push(`${detail.category} - ${detail.keyword} - ${detail.counts} étudiants`);
		data.push(detail.counts);
		const percentage = ((detail.counts / totalLikes) * 100).toFixed(2);
		tableRows.push({
			keyword: detail.keyword,
			category: detail.category,
			students: detail.counts,
			percentage: `${percentage}%`
		});
	});

	return {
		labels,
		data,
		tableRows,
		totalLikes
	};
};

// Function to apply selected filters to the rows
const applyFiltersToRows = (rows, filters) => {
	const selectedRegions = filters.region.options
		.filter(option => option.selected)
		.map(option => option.label);
	const selectedFilieres = filters.filiere.options
		.filter(option => option.selected)
		.map(option => option.label);
	const selectedLocalisations = filters.localisation.options
		.filter(option => option.selected)
		.map(option => option.label);
	const selectedLikes = filters.likes.options.filter(option => option.selected).map(option => option.label);

	return rows.filter(row => {
		const region = row.dimensionValues[0].value;
		const filiere = row.dimensionValues[1].value;
		const localisation = row.dimensionValues[2].value;
		const isLiked =
			row.dimensionValues[4].value === "like" || row.dimensionValues[4].value === "super_like";

		const matchesRegion = selectedRegions.length === 0 || selectedRegions.includes(region);
		const matchesFiliere = selectedFilieres.length === 0 || selectedFilieres.includes(filiere);
		const matchesLocalisation =
			selectedLocalisations.length === 0 || selectedLocalisations.includes(localisation);
		const matchesLikes =
			selectedLikes.length === 0 ||
			(selectedLikes.includes("Avec likes") && isLiked) ||
			(selectedLikes.includes("Sans likes") && !isLiked);

		return matchesRegion && matchesFiliere && matchesLocalisation && matchesLikes;
	});
};

export const aggregateDataByKeywordsAndTraining = (rows, cardsByTrainings, filters) => {
	const aggregatedData = {};
	const filteredRows = applyFiltersToRows(rows, filters);

	if (filteredRows.length === 0 || !filteredRows) return undefined;

	const selectedCategories = filters.categories.options
		.filter(option => option.selected)
		.map(option => option.label);
	// Parse each row and construct the data by trainingId and keyword
	filteredRows.forEach(row => {
		// Extract cardId and likes from the row
		const cardId = row.dimensionValues[5].value; // Assuming cardId is always at index 5
		const likes = parseInt(row.metricValues[0].value); // Assuming likes is always the first metric

		// Iterate over each trainingId in cardsByTrainings to check if the current cardId exists within it
		Object.keys(cardsByTrainings).forEach(trainingId => {
			const trainingCards = cardsByTrainings[trainingId];

			if (trainingCards.hasOwnProperty(cardId)) {
				const metadata = trainingCards[cardId].metadata;

				if (metadata) {
					const { cleanSubtopic, cleanMatchList } = metadata;

					if (selectedCategories.length > 0 && !selectedCategories.includes(cleanSubtopic)) return;
					// Initialize the structure for a trainingId if it doesn't already exist
					if (!aggregatedData[trainingId]) {
						aggregatedData[trainingId] = {};
					}

					// Initialize the dictionary for a keyword if it doesn't already exist for this trainingId
					if (!aggregatedData[trainingId][cleanSubtopic]) {
						aggregatedData[trainingId][cleanSubtopic] = {};
					}
					if (!aggregatedData[trainingId][cleanSubtopic][cleanMatchList[0]]) {
						aggregatedData[trainingId][cleanSubtopic][cleanMatchList[0]] = { counts: 0 };
					}

					// Accumulate likes and add unique categories and themes
					aggregatedData[trainingId][cleanSubtopic][cleanMatchList[0]].counts += likes;
				}
			}
		});
	});

	return aggregatedData;
};

export const getKeywordData = (rows, keyword, cardsByTrainings, filters, totalLikes, date) => {
	let postFilter = applyFiltersToRows(rows, filters);

	const selectedCategories = filters.categories.options
		.filter(option => option.selected)
		.map(option => option.label);

	const allDates = getDatesArray(moment(date.from), moment(date.to));
	const emptyData = new Array(allDates.length).fill(0);
	// Initialize the keyword data structure for chart.js
	const keywordData = {
		region: { labels: [], datasets: [{ data: [] }] },
		filiere: { labels: [], datasets: [{ data: [] }] },
		localisation: { labels: [], datasets: [{ data: [] }] },
		inTime: { labels: allDates, datasets: [{ data: emptyData }] }
	};

	if (postFilter.length === 0) return undefined;

	// Filter rows based on matching the keyword in any associated training's card metadata.
	const filteredRows = postFilter.filter(row => {
		const cardId = row.dimensionValues[5].value; // Extract the cardId from the row
		return Object.keys(cardsByTrainings).some(trainingId => {
			const trainingCards = cardsByTrainings[trainingId];
			if (!trainingCards[cardId] || !trainingCards[cardId]?.metadata) {
				return false; // Skip if the cardId is not found under this trainingId
			}
			const cardMetadata = trainingCards[cardId].metadata;

			if (!cardMetadata) return false;

			const cleanMatchList = cardMetadata.cleanMatchList; // Assuming cleanMatchList is an array

			if (selectedCategories.length > 0 && !selectedCategories.includes(cardMetadata.cleanSubtopic))
				return false;

			return cleanMatchList && cleanMatchList.includes(keyword);
		});
	});

	let totalLikesCount = 0;

	// Aggregate data by category from the filtered rows
	filteredRows.forEach(row => {
		const region = row.dimensionValues[0].value === "(not set)" ? "Autres" : row.dimensionValues[0].value;
		const filiere =
			row.dimensionValues[1].value === "(not set)" ? "Autres" : row.dimensionValues[1].value;
		const localisation =
			row.dimensionValues[2].value === "(not set)" ? "Autres" : row.dimensionValues[2].value;

		const date = row.dimensionValues[3].value;
		const count = parseInt(row.metricValues[0].value); // Assuming likes is always the first metric
		totalLikesCount += count; // Accumulate the total likes

		// Helper function to add or update dataset entries
		const updateDataset = (category, key, value) => {
			const index = keywordData[category].labels.indexOf(key);

			if (index === -1) {
				keywordData[category].labels.push(key);
				keywordData[category].datasets[0].data.push(value);
			} else {
				keywordData[category].datasets[0].data[index] += value;
			}
		};

		updateDataset("region", region, count);
		updateDataset("filiere", filiere, count);
		updateDataset("localisation", localisation, count);
		updateDataset("inTime", date, count);
	});

	keywordData.region = processPieChartData(processLabels(keywordData.region));
	keywordData.filiere = processPieChartData(processLabels(keywordData.filiere));

	const studentsCount = totalLikesCount;
	const taux = ((studentsCount / totalLikes) * 100).toFixed(2) + "%";

	return { keywordData, studentsCount, taux, filteredRows: filteredRows };
};

export function getMultilineChartData(type, filteredRows, date) {
	const allDates = getDatesArray(moment(date.from), moment(date.to));
	const datasets = {};

	// Aggregate data by category from the filtered rows
	filteredRows.forEach(row => {
		let value;
		switch (type) {
			case "region":
				value = row.dimensionValues[0].value;
				break;
			case "filiere":
				value = row.dimensionValues[1].value;
				break;
			case "localisation":
				value = row.dimensionValues[2].value;
				break;
			default:
				value = row.dimensionValues[0].value; // Default to region if type is unspecified
		}

		const dateValue = moment(row.dimensionValues[3].value).format("YYYYMMDD");
		const count = parseInt(row.metricValues[0].value, 10);

		if (!datasets[value]) {
			datasets[value] = {
				label: value,
				data: new Array(allDates.length).fill(0)
			};
		}

		const dateIndex = allDates.indexOf(dateValue);
		if (dateIndex !== -1) {
			datasets[value].data[dateIndex] += count;
		}
	});

	// Transform object into array and sort it by the sum of counts, descending
	let datasetsArray = Object.values(datasets);
	datasetsArray.sort((a, b) => {
		const sumA = a.data.reduce((acc, num) => acc + num, 0);
		const sumB = b.data.reduce((acc, num) => acc + num, 0);
		return sumB - sumA;
	});

	// Keep only the top 8 and aggregate the rest into 'Other'
	if (datasetsArray.length > 8) {
		const otherData = new Array(allDates.length).fill(0);
		datasetsArray.slice(8).forEach(dataset => {
			dataset.data.forEach((count, index) => {
				otherData[index] += count;
			});
		});

		datasetsArray = datasetsArray.slice(0, 8);
		datasetsArray.push({
			label: "Autres",
			data: otherData
		});
	}

	return {
		labels: allDates,
		datasets: datasetsArray
	};
}

// Function to process pie chart data and add percentage to labels
function processLabels(data) {
	let total = data.datasets[0].data.reduce((acc, val) => acc + val, 0);
	data.labels = data.labels.map((label, index) => {
		const percentage = ((data.datasets[0].data[index] / total) * 100).toFixed(1);
		return `${label} - ${percentage}%`;
	});
	return data;
}

function getDatesArray(startDate, endDate) {
	const dates = [];
	const currentDate = moment(startDate);
	const endDateObj = moment(endDate);

	while (currentDate <= endDateObj) {
		dates.push(currentDate.format("YYYYMMDD"));
		currentDate.add(1, "day");
	}

	return dates;
}
