import "./style.css";
import convert from "./icons/f.svg";
import hat from "./icons/hat.svg";
import chat from "./icons/chat.svg";
import stat from "./icons/stat.svg";
import { Home } from "../../components/LandingComponents/home/Home";
import { App } from "../../components/LandingComponents/App/App";
import { NavigationBar } from "../../components/LandingComponents/navBar/NavBar";
import Footer from "../../components/Footer/Footer";
import { Advantages } from "../../components/LandingComponents/Advantages/Advantages";
import { Partners } from "../../components/LandingComponents/Partners/Partners";
import { Communication } from "../../components/LandingComponents/Communications/Communication";
import { Leads } from "../../components/LandingComponents/Leads/Leads";
import { Contact } from "../../components/LandingComponents/Contact/Contact";
import { motion, useInView } from "framer-motion";
import { useRef } from "react";
import { Supports } from "../../components/LandingComponents/Supports/Supports";

export const LandingPage = () => {
	const ref = useRef(null);
	const isInView = useInView(ref, { once: true });

	return (
		<>
			<main className={"landing"}>
				<NavigationBar />
				<Home />
				<div ref={ref}>
					<motion.div
						initial={{ opacity: 0, x: "-100%" }}
						exit={{ opacity: 0, x: "-100%" }}
						transition={{ ease: "easeOut", duration: 0.6, delay: 0.2 }}
						animate={isInView ? { opacity: 1, x: 0 } : {}}
						id={"home-band"}>
						<div>
							<img src={convert} alt={"e-reputation"} />
							Améliorez votre e-réputation
						</div>
						<div>
							<img src={hat} alt={"valoriser formations"} />
							Valorisez vos formations
						</div>
						<div>
							<img src={stat} alt={"tendances"} />
							Analysez les tendances
						</div>
						<div>
							<img src={chat} alt={"contact étudiants"} />
							Restez au contact des étudiants
						</div>
					</motion.div>
				</div>
				<Partners />
				<App />
				<Advantages />
				<Communication />
				<Leads />
				<Contact />
				<Supports />
			</main>
			<Footer />
		</>
	);
};
