import { Global } from "../components/Stats/Globals/Global";
import { useEffect } from "react";

import statsService from "../services/stats/statsService";
import { useSelector } from "react-redux";
import { Details } from "../components/Stats/Details/Details";
import { MetricsMainSelector } from "../components/Stats/Globals/Selector";

const Statistiques = () => {
	const selectedTraining = useSelector(state => state.stats.selectedTraining);

	const fetchGlobal = statsService.useFetchGlobal();

	const from = useSelector(state => state.stats.from);
	const to = useSelector(state => state.stats.to);
	const loading = useSelector(state => state.stats.loading);

	useEffect(() => {
		const fetch = async () => {
			await fetchGlobal.mutateAsync({ from: from, to: to });
		};

		if (loading) {
			fetch();
		}
	}, [from, to]);

	return (
		<>
			<div className="fragment  acc-fra">
				<div className={"fragment_left"}>
					<MetricsMainSelector loading={loading} />
					<Global selectedTraining={selectedTraining} />
					<Details selectedTraining={selectedTraining} loading={loading} />
				</div>
			</div>
		</>
	);
};

export default Statistiques;
