import "../components/FAQ/style.css";
import { useState } from "react";
import { Displayed } from "../components/FAQ/Displayed/Displayed";
import { Selector } from "../components/Client/Trainings/selector/Selector";
import { Pending } from "../components/FAQ/Pending/Pending";
import { useSelector } from "react-redux";
import { countPages } from "../components/Client/utils";
import { UpgradeBtn } from "../components/Stats/Graphs/NotPremiums/UpgradeBtn";

export const FAQ = () => {
	const [selectedTraining, setSelectedTraining] = useState(undefined);

	const search = useSelector(state => state.trainings.content);
	const { premiums } = countPages(search);

	return (
		<div className="fragment" id={"fullpage"}>
			<div className="fragment_left stats" style={{ height: "100%" }}>
				<div className={"row2 selector-color"}>
					<Selector
						selectedTraining={selectedTraining}
						setSelectedTraining={setSelectedTraining}
						all={true}
					/>

					{premiums === 0 && <UpgradeBtn />}
				</div>

				<div className="faq-container">
					<Displayed selectedTraining={selectedTraining} />
					<Pending selectedTraining={selectedTraining} />
				</div>
			</div>
		</div>
	);
};
