import React, { useEffect, useState } from "react";
import { FilterSelector } from "../components/Table/FilterSelector";
import { useDispatch, useSelector } from "react-redux";
import { avisMethods } from "../models/avis";
import avisService from "../services/avis/avisService";
import { CardAvis } from "../components/Admin/Avis/CardAvis";
import { CircularLoader } from "../components/LoadingComponents/CircularLoader";

export const Avis = () => {
	const trainings = useSelector(state => state.trainings);

	const [selectedTraining, setSelectedTraining] = useState(undefined);

	useEffect(() => {
		setSelectedTraining(trainings.content[0]?.trainingId);
	}, [trainings.loaded, trainings.content]);

	const filters = useSelector(state => state.avis.filters);
	const selected = useSelector(state => state.avis.selected);
	const dispatch = useDispatch();
	const avis = useSelector(state => state.avis);

	const content = useSelector(state => state.avis.verified[selectedTraining]);
	const onSelect = value => {
		dispatch(avisMethods.setSelected(value));
	};

	const service = avisService.useFetchAvis();

	const fetch = async () => {
		await service.mutateAsync({
			trainingId: selectedTraining,
			skip: content?.avis?.length || 0,
			max: 15,
			type: selected
		});
	};

	useEffect(() => {
		if ((!content || content.loading) && selectedTraining) {
			fetch();
		}
	}, [selectedTraining, selected]);

	const formattedFilters = filters => {
		return filters.map(filter => {
			return {
				key: filter.key,
				value: filter.value + " (" + (avis[filter.key][selectedTraining].total || 0) + ")"
			};
		});
	};

	return (
		<div className="fragment" id={"fullpage"}>
			<div className="fragment_left stats" style={{ height: "100%" }}>
				<div style={{ paddingBottom: 50 }}>
					{content ? (
						<>
							{filters && (
								<FilterSelector
									setFilter={onSelect}
									filters={formattedFilters(filters)}
									selected={selected}
								/>
							)}

							<div className={"avis-container"} style={{ marginTop: 8 }}>
								{content?.avis?.map((item, index) => {
									return <CardAvis avis={item} key={item._id} />;
								})}
							</div>

							{content?.avis?.length > 0 && content?.avis?.length < content?.total && (
								<button onClick={fetch} className={"btntab"}>
									{service.isPending ? (
										<div
											style={{
												width: "100%",
												display: "flex",
												alignItems: "center",
												justifyContent: "center"
											}}>
											<CircularLoader height={50} width={50} />
										</div>
									) : (
										"Voir plus"
									)}
								</button>
							)}
							{content.loading && (
								<div
									style={{
										height: 300,
										display: "flex",
										justifyContent: "center",
										alignItems: "center"
									}}>
									<CircularLoader width={70} height={70} />
								</div>
							)}

							{content?.avis?.length == 0 && !content.loading && (
								<p>Pas d'avis pour le moment.</p>
							)}
						</>
					) : (
						<div
							style={{
								display: "flex",
								width: "100%",
								alignItems: "center",
								justifyContent: "center"
							}}>
							<CircularLoader width={70} height={70} />
						</div>
					)}
				</div>
			</div>
		</div>
	);
};
