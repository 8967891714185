import "./dashboard.css";
import LineChart from "../Graphs/Line/LineChart";
import { useDispatch, useSelector } from "react-redux";
import { statsMethods } from "../../../models/metrics";
import { GraphLoader } from "../../LoadingComponents/GraphLoader";
import { useMemo } from "react";
import { trackEvent2 } from "../../../utils/ga";

const metricInfo = ["visites", "clics sur le lien du site internet", "j'aimes"];
export const Global = ({ selectedTraining }) => {
	const selectedMetric = useSelector(state => state.stats?.selectedMetric);
	const dispatch = useDispatch();
	const client = useSelector(state => state.client);

	const onSelectMetric = index => {
		dispatch(statsMethods.setGlobalSelected({ selectedMetric: index }));
		if (!client.admin) {
			trackEvent2("select_event", "Metrics", "select", "global", {
				structureId: client.structureId,
				acronym: client.acronym,
				type: metricInfo[index]
			});
		}
	};

	const trainings = useSelector(state => state.stats.trainings);
	const globalsCount = useSelector(state => state.stats.globalsCount);
	const loading = useSelector(state => state.stats.loading);

	// Determine the metric data and count to display
	const { metricData, currentCount } = useMemo(() => {
		const metricData = getMetricData(
			selectedMetric,
			Object.values(selectedTraining ? [trainings[selectedTraining]] : trainings)
		);
		const currentCount = selectedTraining
			? calculateTrainingCounts(trainings[selectedTraining])
			: globalsCount;

		return { metricData, currentCount };
	}, [selectedTraining, selectedMetric, loading]);

	return (
		<div style={{ marginTop: 20 }}>
			<h3>Statistiques globales des pages </h3>
			<div className={"dashboard"} style={{ marginTop: 20, position: "relative" }}>
				<div className={"column"}>
					<div
						className={`${selectedMetric === 0 ? "selected" : ""} row2`}
						onClick={() => onSelectMetric(0)}>
						<h1 className={"numbers"}>{currentCount.visits}</h1>
						<h3>visites</h3>
					</div>

					<div
						className={`${selectedMetric === 1 ? "selected" : ""} row2`}
						onClick={() => onSelectMetric(1)}>
						<h1 className={"numbers"}>{currentCount.websiteOpenings}</h1>
						<h3>clics sur le lien du site internet</h3>
					</div>

					<div
						className={`${selectedMetric === 2 ? "selected" : ""} row2`}
						onClick={() => onSelectMetric(2)}>
						<h1 className={"numbers"}>{currentCount.likes}</h1>
						<h3>j'aimes</h3>
					</div>
				</div>
				<div className={"lineChart"}>
					{loading && <GraphLoader />}
					{metricData.labels && metricData.datasets && <LineChart metric={metricData} />}
				</div>
			</div>
		</div>
	);
};

const calculateTrainingCounts = training => {
	const counts = {
		visits: 0,
		likes: 0,
		impressions: 0,
		websiteOpenings: 0
	};

	if (training?.metric) {
		counts.visits = training.metric[0]?.count || 0;
		counts.websiteOpenings = training.metric[1]?.count || 0;
		counts.likes = training.metric[2]?.count || 0;
		counts.impressions = training.metric[3]?.count || 0;
	}

	return counts;
};

export const getMetricData = (selectedMetric, trainings) => {
	if (!trainings || trainings.length === 0) {
		return {
			labels: [],
			datasets: []
		};
	}

	const allLabels = new Set();
	trainings.forEach(training => {
		training?.metric[selectedMetric]?.metric.labels.forEach(label => allLabels.add(label));
	});

	const sortedLabels = Array.from(allLabels).sort((a, b) => {
		const dateA = new Date(a.split(" ")[0].split("-").reverse().join("-"));
		const dateB = new Date(b.split(" ")[0].split("-").reverse().join("-"));
		return dateA - dateB;
	});

	const datasets = trainings[0]?.metric[selectedMetric]?.metric.datasets.map(dataset => ({
		...dataset,
		data: Array(sortedLabels.length).fill(0)
	}));

	trainings.forEach(training => {
		const trainingMetric = training?.metric[selectedMetric];
		if (!trainingMetric) {
			return;
		}
		trainingMetric.metric.datasets.forEach((dataset, datasetIndex) => {
			dataset.data.forEach((value, dataIndex) => {
				const date = trainingMetric.metric.labels[dataIndex];
				const globalDataIndex = sortedLabels.indexOf(date);
				if (typeof value === "object" && value !== null && "eventCount" in value) {
					datasets[datasetIndex].data[globalDataIndex] += value.eventCount;
				} else {
					datasets[datasetIndex].data[globalDataIndex] += value;
				}
			});
		});
	});

	return {
		labels: sortedLabels,
		datasets
	};
};
