import {
	CategoryScale,
	Chart as ChartJS,
	Legend,
	LinearScale,
	LineElement,
	PointElement,
	Title,
	Tooltip
} from "chart.js";
import { Line } from "react-chartjs-2";
import { useEffect, useRef, useState } from "react";
import "./lineGraph.css";
import "moment/locale/fr";
import { useSelector } from "react-redux";
import moment from "moment"; // Importer la locale française pour moment

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

const LineChart = ({ metric }) => {
	const chartRef = useRef(null);
	const [refreshKey, setRefreshKey] = useState(0);

	const options = {
		responsive: true,
		animation: false,
		plugins: {
			legend: {
				display: false
			},
			title: {
				display: true,
				text: metric?.datasets[0]?.label ?? "Nombre de visites au cours du temps",
				font: {
					size: 17,
					color: "#7A8596"
				}
			},
			tooltip: {
				mode: "index", // Affiche les données de la colonne entière
				intersect: false, // Affiche même si le curseur n'intersecte pas un point
				displayColors: false, // This line disables the color box in the tooltip
				titleFont: {
					size: 16, // Taille du texte du titre
					fontWeight: "semibold"
				},
				bodyFont: {
					size: 16, // Taille du texte du corps
					fontWeight: "bold"
				},

				footerFont: {
					size: 14, // Taille du texte du pied de page
					fontWeight: "normal"
				}
			},
			crosshair: {
				line: {
					color: "#d3d3d3", // Couleur du trait pointillé
					width: 1, // Largeur du trait pointillé
					dashPattern: [5, 5]
				},
				sync: {
					enabled: false // Synchronisation des curseurs entre plusieurs graphiques (désactivé ici)
				},
				zoom: {
					enabled: false // Zoom activé par le crosshair (désactivé ici)
				}
			}
		},
		scales: {
			x: {
				grid: {
					display: false
				},
				ticks: {
					autoSkip: true,
					maxTicksLimit: 10 // Ajustez ce nombre en fonction de la largeur disponible
				}
			},
			y: {
				grid: {
					display: true
				},
				ticks: {
					precision: 0 // Force ticks to be integers
				}
			}
		},
		elements: {
			point: {
				radius: 0, // Taille du point
				hoverRadius: 0 // Taille du point au survol
			}
		}
	};

	const data = {
		labels: metric?.labels?.map(date => moment(date, "YYYYMMDD").format("D MMM")),
		datasets: [
			{
				label: metric?.datasets[0]?.label,
				data: metric?.datasets[0]?.data,
				borderColor: "#3784EB",
				backgroundColor: "#3784EB",
				pointRadius: 0, // Taille du point
				pointHoverRadius: 0 // Taille du point au survol
			}
		]
	};

	const refreshChart = () => {
		setRefreshKey(prevKey => prevKey + 1);
	};

	useEffect(() => {
		const handleResize = () => {
			refreshChart();
		};

		window.addEventListener("resize", handleResize);

		return () => {
			window.removeEventListener("resize", handleResize);
		};
	}, []);

	useEffect(() => {
		refreshChart();
	}, [metric]);

	const activeNavBar = useSelector(state => state.app.activeNavBar);

	useEffect(() => {
		setTimeout(() => {
			refreshChart();
			chartRef?.current?.resize();
		}, 200);
	}, [activeNavBar]);

	return (
		<Line
			ref={chartRef}
			key={refreshKey}
			options={options}
			data={data}
			plugins={[
				{
					beforeDraw: chart => {
						if (chart.tooltip._active && chart.tooltip._active.length) {
							const ctx = chart.ctx;
							const x = chart.tooltip._active[0].element.x;
							const topY = chart.scales.y.top;
							const bottomY = chart.scales.y.bottom;

							ctx.save();
							ctx.beginPath();
							ctx.setLineDash([5, 5]);
							ctx.moveTo(x, topY);
							ctx.lineTo(x, bottomY);
							ctx.lineWidth = 1;
							ctx.strokeStyle = "#d3d3d3";
							ctx.stroke();
							ctx.restore();
						}
					}
				}
			]}
		/>
	);
};

export default LineChart;
