import { apiClient, fileClient } from "../clients";
import {
	contactRoute,
	fetchClientData,
	loginRoute,
	passwordChangeRoute,
	registerRequestRoute
} from "../../utils/APIRoutes";

/**
 * Effectue une requête de connexion avec les informations d'identification fournies.
 * @param {Object} credentials - Les informations d'identification de l'utilisateur.
 * @returns {Promise<Object>} Les données de réponse de l'API.
 * @throws {Error} Si une erreur se produit lors de la requête.
 */
const login = async credentials => {
	try {
		const response = await apiClient.post(loginRoute, credentials);
		return response.data;
	} catch (e) {
		if (e.response && e.response.data && e.response.data.message) {
			throw new Error(e.response.data.message);
		}
		throw new Error("Une erreur c'est produite.");
	}
};

/**
 * Récupère les données du client avec les informations d'identification fournies.
 * @param {Object} credentials - Les informations d'identification de l'utilisateur.
 * @returns {Promise<Object>} Les données de réponse de l'API.
 * @throws {Error} Si une erreur se produit lors de la requête.
 */
const fetch = async credentials => {
	try {
		const response = await apiClient.get(fetchClientData, credentials);
		return response.data;
	} catch (e) {
		throw e;
	}
};

/**
 * Met à jour les données du client avec les informations d'identification fournies.
 * @param {Object} credentials - Les informations d'identification de l'utilisateur.
 * @returns {Promise<Object>} Les données de réponse de l'API.
 * @throws {Error} Si une erreur se produit lors de la requête.
 */
const patch = async credentials => {
	try {
		const response = await apiClient.post(fetchClientData + "/patch", credentials);
		return response.data;
	} catch (e) {
		if (e.response && e.response.data && e.response.data.message) {
			throw new Error(e.response.data.message);
		}
		throw new Error("Une erreur c'est produite.");
	}
};

/**
 * Enregistre un nouvel utilisateur avec les informations d'identification fournies.
 * @param {Object} credentials - Les informations d'identification de l'utilisateur.
 * @returns {Promise<Object>} Les données de réponse de l'API.
 * @throws {Error} Si une erreur se produit lors de la requête.
 */
const register = async credentials => {
	try {
		const response = await fileClient.post(registerRequestRoute, credentials);
		return response.data;
	} catch (e) {
		if (e.response && e.response.data && e.response.data.message) {
			throw new Error(e.response.data.message);
		}
		throw new Error("Une erreur c'est produite.");
	}
};

/**
 * Change le mot de passe de l'utilisateur avec les informations d'identification fournies.
 * @param {Object} credentials - Les informations d'identification de l'utilisateur.
 * @returns {Promise<Object>} Les données de réponse de l'API.
 * @throws {Error} Si une erreur se produit lors de la requête.
 */
const changePassword = async credentials => {
	try {
		const response = await apiClient.post(passwordChangeRoute, credentials);
		return response.data;
	} catch (e) {
		if (e.response && e.response.data && e.response.data.message) {
			throw new Error(e.response.data.message);
		}
		throw new Error("Une erreur c'est produite.");
	}
};

/**
 * Envoie un message de contact avec les informations d'identification fournies.
 * @param {Object} credentials - Les informations d'identification de l'utilisateur.
 * @returns {Promise<Object>} Les données de réponse de l'API.
 * @throws {Error} Si une erreur se produit lors de la requête.
 */
const contact = async credentials => {
	try {
		const response = await apiClient.post(contactRoute, credentials);
		return response.data;
	} catch (e) {
		if (e.response && e.response.data && e.response.data.message) {
			throw new Error(e.response.data.message);
		}
		throw new Error("Une erreur c'est produite.");
	}
};

/**
 * Objet contenant toutes les fonctions d'appel API.
 */
const apiCalls = {
	login,
	fetch,
	patch,
	register,
	changePassword,
	contact
};

export default apiCalls;
