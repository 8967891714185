import clientService from "../../services/client/clientService";
import { useEffect } from "react";
import { appMethods } from "../../models/app";
import { useDispatch } from "react-redux";

export const ClientLoader = () => {
	const { mutateAsync, isPending } = clientService.useFetch();
	const dispatch = useDispatch();

	useEffect(() => {
		(async () => {
			try {
				await mutateAsync();
			} catch (e) {
				console.log(e);
			}
		})();
	}, []);

	useEffect(() => {
		if (localStorage.getItem("dark_mode") !== undefined && localStorage.getItem("dark_mode") !== null) {
			document.body.classList.add("dark_mode");
			dispatch(appMethods.setIsDark(true));
		}
	}, []);
	return <></>;
};
