import { useNavigate } from "react-router-dom";
import Login_Form from "../components/Forms/LoginForm";

import logo from "../assets/logo.png";
import Toggle from "../components/navigation/theme-toggle/toggle";

const Login = ({ isDark, setIsDark }) => {
	const navigate = useNavigate();

	return (
		<div className="home-page page-container loginpage">
			<div className="left home">
				<header>
					<div className="home-logo-container">
						<img src={logo} alt="" />
					</div>
					<Toggle isDark={isDark} setIsDark={setIsDark}></Toggle>
				</header>
				<main>
					<h1 className="bigtitle">Se connecter.</h1>
					<p className="resume">Connectez vous pour accéder à votre profil...</p>
					<button onClick={() => navigate("/")}>Revenir a la page d'accueil →</button>
				</main>
			</div>
			<div className="login-container">
				<Login_Form />
			</div>
		</div>
	);
};

export default Login;
