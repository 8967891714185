import { useNavigate } from "react-router-dom";
import Register_Form from "../components/Forms/Formulaires/Register-form";
import logo from "../assets/logo.png";
import Toggle from "../components/navigation/theme-toggle/toggle";

const Register = () => {
	const navigate = useNavigate();

	return (
		<div className="home-page page-container loginpage">
			<div className="left home">
				<header>
					<div className="home-logo-container">
						<img src={logo} alt="" />
					</div>
					<Toggle></Toggle>
				</header>
				<main>
					<h1 className="bigtitle">Faire une demande d'inscription.</h1>
					<p className="resume">
						Remplissez les différents champs nous permettant de traiter votre demande
						d'inscription.
					</p>
					<button onClick={() => navigate("/")}>Revenir a la page d'accueil →</button>
				</main>
			</div>
			<div className="login-container">
				<Register_Form />
			</div>
		</div>
	);
};

export default Register;
